import jQuery from '../utils/fl-jquery';
import config from '../config';
import constants from '../constants';
import { identity } from '@findologic/js-common';

/**
 * A thing that allows sending search to FINDOLOGIC.
 *
 * @param {{}} settings
 * @param {int} [settings.timeout] Request timeout in milliseconds.
 * @param {function} [settings.onSuccess] Called if the request succeeds within an acceptable time frame.
 * @param {function} [settings.onError] Called if the request fails with an error.
 * @param {function} [settings.onTimeout] Called if the request times out.
 * @param {function} [settings.onComplete] Called once the request is regarded as complete, regardless of success,
 * error, or timeout.
 * @returns {Request}
 * @constructor
 */
function Request(settings) {
    if (!(this instanceof Request)) {
        return new Request(settings);
    }

    const self = this;
    this.settings = settings;

    // Set defaults:
    !this.settings.hasOwnProperty('timeout') ? (this.settings.timeout = 3000) : undefined;
    jQuery.each(['onSuccess', 'onError', 'onTimeout', 'onComplete'], function (i, callbackName) {
        !self.settings.hasOwnProperty(callbackName) ? (self.settings[callbackName] = function () {}) : undefined;
    });

    const essentialParams = {};
    essentialParams[constants.SHOP_URL] = config.shopUrl;
    essentialParams[constants.SHOP_KEY] = config.shopkey;
    essentialParams[constants.QUERY_PARAMS_REQUEST_ID] = identity.createUUID();

    /**
     * Builds the URL to send the request to based on type of request and configuration. Generally, all requests go
     * directly to the FINDOLOGIC API, unless it's a suggest request, and the autocomplete proxy has been enabled.
     *
     * @param {'search'|'navigation'|'suggest'} type Type of request.
     * @return {string} Smart Suggest endpoint to use.
     */
    function buildRequestUrl(type) {
        if (config.useAutocompleteProxy && type === Request.TYPE_SUGGEST) {
            return config.autocompleteProxy;
        } else {
            return config.directIntegration.frontend + Request.ENDPOINTS[type];
        }
    }

    /**
     * Determines the desired return data format for the request based on whether this is a proxied Smart Suggest
     * request or not.
     *
     * @param {'search'|'navigation'|'suggest'} type Type of request.
     * @returns {string} 'jsonp' for proxied Smart Suggest requests, 'text' otherwise.
     */
    function getDataType(type) {
        if (config.useAutocompleteProxy && type === Request.TYPE_SUGGEST) {
            return 'text';
        } else {
            return 'jsonp';
        }
    }

    /**
     * @param {'search'|'navigation'|'suggest'} type Type of request.
     * @param {{}} params URL parameters that extend and override the defaults.
     * @return {jqXHR} XHR object returned by jQuery, which can be used to abort a request.
     */
    this.send = function (type, params) {
        const timeoutHandle = setTimeout(function () {
            jqXhr.abort();

            self.settings.onTimeout();
            self.settings.onComplete();
        }, this.settings.timeout);

        if (params['usergrouphash'] !== undefined) {
            essentialParams.usergrouphash = params['usergrouphash'];
        } else {
            essentialParams.usergrouphash = identity.getUsergrouphash();
        }

        // Ignore empty usergroups
        if (jQuery.trim(essentialParams.usergrouphash) === '') {
            delete params['usergrouphash'];
            delete essentialParams.usergrouphash;
        }

        const jqXhr = jQuery.ajax({
            type: 'GET',
            url: buildRequestUrl(type),
            contentType: 'application/json',
            // cache: false would append a cache buster to the request url (_=<timestamp>). This is not
            // necessary, because server configuration disallows browser caching anyway, so we can suppress
            // the cache buster safely by setting this value to true. This has to do with how the jsonp
            // mechanism uses a script tag with a callback containing the response as a way to cross origins.
            cache: true,
            dataType: getDataType(type),
            data: jQuery.extend({}, params, essentialParams),
            success(response) {
                self.settings.onSuccess(response);
            },
            error(response) {
                // The error callback of jQuery.ajax is invoked when the request is aborted by the timeout.
                // In that case, prevent Request's error callback from firing.
                if (response.statusText !== 'abort') {
                    self.settings.onError(response);
                }
            },
            complete() {
                clearTimeout(timeoutHandle);
                self.settings.onComplete();
            },
        });

        return jqXhr;
    };
}

/**
 * Request type search.
 *
 * @type {string}
 */
Request.TYPE_SEARCH = 'search';

/**
 * Request type navigation.
 *
 * @type {string}
 */
Request.TYPE_NAVIGATION = 'navigation';

/**
 * Request type Smart Suggest.
 *
 * @type {string}
 */
Request.TYPE_SUGGEST = 'suggest';

/**
 * API endpoints for the respective request types.
 *
 * @type {{}}
 */
Request.ENDPOINTS = {};
Request.ENDPOINTS[Request.TYPE_SEARCH] = 'index.php';
Request.ENDPOINTS[Request.TYPE_NAVIGATION] = 'selector.php';
Request.ENDPOINTS[Request.TYPE_SUGGEST] = 'autocomplete.php';

export default Request;
